import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Drill into Dashbot’s Behavior Flow to identify what causes users to leave.`}</p>
    <p>{`We’re excited to launch Behavior Flow! Many customers asked for a report that showed the last message users received or sent before leaving the conversation. Customers were interested in seeing the messages that provoked users to abandon conversations so that they could revise their bot to increase engagement and reduce churn.`}</p>
    <h2>{`Behavior Flow`}</h2>
    <p>{`Using the `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/?utm_source=blog&utm_medium=medium&utm_campaign=productlaunch_exitflows"
      }}>{`Behavior Flow`}</a>{` chart, you can analyze the engagement of each message and visually identify common paths that lead to success or abandonment. For the example below, Message C attributes to 62.45% of the sessions that were abandoned. In total, after the first outgoing message, 23.60% of the sessions resulted in conversation abandonment.`}</p>
    <h2><figure><img parentName="h2" {...{
          "src": "https://i.dashbot.io/wp-home/uploads/2018/02/06112533/1ca11-1vs_73rold_mobgobnguxia.png",
          "alt": null
        }}></img></figure>{`Identify Exit Messages`}</h2>
    <p>{`Within `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/?utm_source=blog&utm_medium=medium&utm_campaign=productlaunch_exitflows"
      }}>{`Message Funnels`}</a>{`, you can now pinpoint which specific messages led to the highest number of conversations ended. For the example below, 903 users left the conversation after they received the current message, “What did you think of that round?”`}</p>
    <figure>
  ![](https://i.dashbot.io/wp-home/uploads/2017/10/06173102/1c86e-1xfuwdbdy_lioex9eywvu-g.png)
    </figure>
Need help understanding your data? Schedule some one-on-one time with us
[here](https://calendly.com/dashbot/).
    <hr></hr>
    <h2>{`About Dashbot`}</h2>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/?utm_source=blog&utm_medium=medium&utm_campaign=productlaunch_exitflows"
        }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</em></p>
    <p><em parentName="p">{`You can view detailed analytics `}{`&`}{` competitive market metrics, analyze conversation transcripts, track message funnels, optimize referral campaigns, understand sentiment `}{`&`}{` demographics, and add human-in-the-loop to your bot — all in real time.`}</em></p>
    <p><em parentName="p">{`We natively support Facebook Messenger, Twitter, Amazon Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface. Sign up for free at `}<a parentName="em" {...{
          "href": "https://www.dashbot.io/?utm_source=blog&utm_medium=medium&utm_campaign=productlaunch_exitflows"
        }}>{`www.dashbot.io`}</a>{`.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      